import React, { useEffect, useState } from "react";
import { Button, Layout, Typography } from "antd";
import axios from "axios";
import { BASE_URL } from "utils/constants";
import { Link } from "react-router-dom";

const { Text } = Typography;

const { Header } = Layout;

const boxStyle: React.CSSProperties = {
  width: "170px",
  height: "100px",
  margin: "16px",
  color: "white",
  textAlign: "center",
  fontSize: 16,
};

type currentUserResponse = {
  name: string;
  allowedEndpoints: string[];
  roleName: string;
};

const ApplicationList: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<currentUserResponse | null>(
    null
  );

  useEffect(() => {
    axios
      .get(BASE_URL + "me")
      .then(function (response) {
        console.log(response);
        setCurrentUser(response.data.content);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleLogout = () => {
    axios
      .get(BASE_URL + "logout")
      .then(function (response) {
        window.location.href = response.data;
      })
      .catch(function (error) {
        window.location.href = "/login";
      });
  };
  return (
    <Header style={{ display: "flex", alignItems: "center" }}>
      <Link to="/" className="demo-logo-vertical">
        CANNY Web App
      </Link>
      <Link to="/firmware_manager" className="demo-logo-vertical">
        Firmware Manager
      </Link>
      <Link to="/canny" className="demo-logo-vertical">
        Canny Manager
      </Link>
      <Link to="/role_management" className="demo-logo-vertical">
        Role Management
      </Link>
      <Link to="/user" className="demo-logo-vertical">
        IAM Manager
      </Link>
      <Link to="/record_data" className="demo-logo-vertical">
        Record Data
      </Link>
      {/* <div className="demo-logo-vertical">PDF Manager</div> */}
      {/* <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['nav0']}
                    disabledOverflow={true}
                    items={items}
                /> */}
      <div style={{ marginLeft: "auto" }}>
        <Text style={boxStyle}>
          Hello, {currentUser?.name} ({currentUser?.roleName})! Glad to see you!
        </Text>
        <Button type="primary" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </Header>
  );
};

export default ApplicationList;
