
import React, { ReactNode, useState } from "react";
import axios from 'axios';

import {
    Modal,
    Form,
    Upload,
    UploadFile,
    UploadProps,
    Button,
    Card
} from 'antd'; // Import Table from antd

import { UploadChangeParam } from "antd/es/upload";

import {UploadOutlined, FilePdfOutlined} from '@ant-design/icons';

// utils
import {BASE_URL} from 'utils/constants';

import {
    useQueryClient,
    useMutation,
} from '@tanstack/react-query'
import { VehicleItem } from "utils/interfaces";

interface Props {
    vehicleId: number,
    showModal: boolean,
    onClose: () => void
}

interface WiringFormData {
    pdf_file: {
        file: File,
        fileList: FileList
    }
}

const downloadWiringPdf = (fileName: string) => {
    axios({
        url: `${BASE_URL}/get_pdf/${fileName}`, //your url
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;

        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
}

const EditWiringPdfModal: React.FC<Props> = ({ vehicleId, showModal, onClose }: Props) => {

    const [form] = Form.useForm<WiringFormData>();

    const [fileList, setFileList] = useState<UploadFile[]>([])

    const queryClient = useQueryClient()

    const getVehicleData = () : VehicleItem | undefined => {
        const data = queryClient.getQueryData<VehicleItem[]>(['vehicleList'])
        if(data) {
            const vehicle = data.find((item) => item.id === vehicleId)
            if(vehicle) {
                return vehicle
            }
        }
        return undefined;
    }

    const vehicleData = getVehicleData()

    const uploadWiringPdfMutation = useMutation({
        mutationFn: (values: WiringFormData) => {
            const formData = new FormData();
            formData.append("vehicle_id", vehicleId.toString())
            formData.append('pdf_file', values.pdf_file.file)
            return axios.post(`${BASE_URL}/upload_pdf_car`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['vehicleList'] }),
        onError: (error) => console.error('Error adding new wiring', error)
    })

    const onFinish = (values: WiringFormData) => {
        // console.log('Received values of form: ', values);
        uploadWiringPdfMutation.mutate(values, {
            onSuccess: () =>  {
                setFileList([]);
                form.setFieldValue('pdf_file', undefined)
                onClose()
            },
            onError: (error) => {
                Modal.error({
                    title: 'Error adding vehicle',
                    content: error.message,
                });
            }
        });
    };

    const getWiringFileNode = (): ReactNode => {
        if(vehicleData && vehicleData.wiring_instruction) {
            return (
                <Card
                    style={{width: 300, display: 'flex', alignItems: 'center'}}
                    onClick={() => {
                        if (vehicleData.wiring_instruction != null) {
                            downloadWiringPdf(vehicleData.wiring_instruction)
                        }
                    }}
                    >
                    <FilePdfOutlined
                        style={{fontSize: '24px', marginRight: '10px', color: '#f5222d'}}/>
                    <span>{vehicleData.wiring_instruction}</span>
                </Card>
            )
        } else {
            return <></>
        }
    }

    const uploadProps: UploadProps = {
        accept: ".pdf",
        maxCount: 1,
        multiple: false,
        onRemove: () => {
            setFileList([]);
            form.setFieldValue('pdf_file', undefined)
        },
        beforeUpload: file => {
            setFileList([file]);
            // form.setFieldValue('pdf_file', file)
            return false;
        },
        fileList: fileList,
    };

    return(
    <Modal
        title="Upload New Wiring PDF"
        open={showModal}
        okText="Upload"
        cancelText="Cancel"
        okButtonProps={{
            autoFocus: false,
            htmlType: 'submit',
        }}
        onCancel={onClose}
        destroyOnClose
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form_upload_pdf"
            clearOnDestroy
            onFinish={(values) => onFinish(values)}
          >
            {dom}
          </Form>
        )}>
        <Form.Item label="Existing PDF file">
            {getWiringFileNode()}
        </Form.Item>
        <Form.Item label="Select PDF files"
                name="pdf_file"
                rules={[{
                    required: true,
                    validator: (_, value: UploadChangeParam) =>{
                        if(value.fileList.length) {
                            return Promise.resolve()
                        } else {
                            return Promise.reject(new Error('Please add one file'))
                        }
                    }
                }]}>
            <Upload {...uploadProps}>
                <Button icon={<UploadOutlined/>}>Select File</Button>
            </Upload>
        </Form.Item>
    </Modal>)
}
export default EditWiringPdfModal;

