import React from "react";

import { Button, Card, Modal } from "antd";
import { CardText } from "react-bootstrap"; // Import Table from antd
// utils

interface Props {
  showModal: boolean;
  onDelete: () => void;
  onClose: () => void;
}

const DeleteModal: React.FC<Props> = ({
  showModal,
  onDelete,
  onClose,
}: Props) => {
  return (
    <Modal
      footer={null}
      open={showModal}
      destroyOnClose={true}
      onCancel={onClose}
      closable={false}
      style={{ padding: "0px" }}
    >
      <Card
        bordered={false}
        title={"Warning!"}
        actions={[
          <Button danger onClick={onDelete}>
            Delete
          </Button>,
          <Button onClick={onClose}>Cancel</Button>,
        ]}
      >
        <CardText>
          Are you sure you want to delete this item? This action is permanent.
        </CardText>
      </Card>
    </Modal>
  );
};
export default DeleteModal;
