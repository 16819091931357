import React, { CSSProperties, useRef, useState } from "react";
import axios from "axios";

import type { TableColumnsType } from "antd";
import {
  Button,
  Card,
  InputRef,
  Space,
  Table,
  TableColumnType,
  Tooltip,
  Typography,
} from "antd"; // Import Table from antd
import {
  CloseOutlined,
  CommentOutlined,
  FilePdfOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { BASE_URL } from "utils/constants";

import {
  JsonSchemaListResponse,
  VehicleItem,
  VehicleItemIndex,
} from "utils/interfaces";

// import {
//     materialCells,
//     materialRenderers,
// } from '@jsonforms/material-renderers';
// import {JsonForms} from '@jsonforms/react';
import AddVehicleModal from "./VehicleListComp/AddVehicleModal";
import EditWiringPdfModal from "./VehicleListComp/EditWiringPdfModal";
import EditVehicleConfigModal from "./VehicleListComp/EditVehicleConfigModal";

import { useQuery } from "@tanstack/react-query";
import { FilterDropdownProps } from "antd/es/table/interface";
import Search from "antd/es/input/Search";
import Highlighter from "react-highlight-words";
import CommentsComponent from "./CommentsComponent";
import EditVehicleModal from "./VehicleListComp/EditVehicleModal";
import DeleteModal from "./DeleteModal";

const { Text } = Typography;

type VehicleListResponse = {
  success: boolean;
  content: VehicleItem[];
};

const VehicleListView: React.FC = () => {
  const emptyCar: VehicleItem = {
    json_config: {},
    created_at: null,
    date_released: null,
    json_config_versions: null,
    id: 0,
    manufacturer: "",
    model: "",
    type: "",
    wiring_instruction: null,
    year: "",
    status: "",
  };

  const [selectedVehicle, setSelectedVehicle] = useState<VehicleItem>(emptyCar);
  const [selectedJsonSchemaId, setSelectedJsonSchemaId] = useState<number>(0);
  const [selectedVehicleConfigId, setSelectedVehicleConfigId] =
    useState<number>(0);

  const [showAddVehicleModal, setShowAddVehicleModal] = useState(false);
  const [showEditVehicleModal, setShowEditVehicleModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showPdfEditModal, setShowPdfEditModal] = useState(false);
  const [showVehicleJsonEditModal, setShowVehicleJsonEditModal] =
    useState(false);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState(0);

  const vehicleListQuery = useQuery({
    queryKey: ["vehicleList"],
    queryFn: async () => {
      const res = await axios.get<VehicleListResponse>(
        `${BASE_URL}get_vehicles`
      );
      return res.data.content;
    },
  });

  const jsonSchemaListQuery = useQuery({
    queryKey: ["jsonSchemaList"],
    queryFn: async () => {
      const res = await axios.get<JsonSchemaListResponse>(
        `${BASE_URL}/get_json_schemas_base`
      );
      return res.data.content;
    },
  });

  const openCommentModal = (vehicle: VehicleItem) => {
    setSelectedVehicle(vehicle);
    setSelectedVehicleId(vehicle.id);
    setShowCommentModal(true);
  };
  const closeCommentModal = () => {
    setShowCommentModal(false);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: VehicleItemIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void, close: () => void) => {
    clearFilters();
    setSearchText("");

    close();
  };
  const handleEdit = (vehicle: VehicleItem) => {
    setSelectedVehicle(vehicle);
    setShowEditVehicleModal(true);
  };

  const getColumnSearchProps = (
    dataIndex: VehicleItemIndex
  ): TableColumnType<VehicleItem> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Search
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onSearch={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "flex" }}
          allowClear={{
            clearIcon: (
              <CloseOutlined
                onClick={() => clearFilters && handleReset(clearFilters, close)}
              />
            ),
          }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <>
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      </>
    ),
    onFilter: (value, record) =>
      // @ts-ignore
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getJsonConfigColumns = (): TableColumnsType<VehicleItem> => {
    var columns: TableColumnsType<VehicleItem> = [];
    jsonSchemaListQuery.data?.forEach((jsonSchema) => {
      columns?.push({
        title: jsonSchema.version_schema_name,
        key: jsonSchema.version_schema_name.trim().toLocaleLowerCase(),
        render: (_text: any, vehicle: VehicleItem) => {
          const styleTest: CSSProperties = {
            backgroundColor: vehicle.json_config_versions![
              jsonSchema.version_schema_name
            ]?.TEST.valid
              ? "green"
              : "red",
          };
          const styleRelease: CSSProperties = {
            backgroundColor: vehicle.json_config_versions![
              jsonSchema.version_schema_name
            ]?.RELEASE.valid
              ? "green"
              : "red",
          };
          return (
            <Space>
              <Button
                size="small"
                style={styleTest}
                onClick={() => {
                  setSelectedJsonSchemaId(jsonSchema.id);
                  setSelectedVehicleConfigId(
                    vehicle.json_config_versions![
                      jsonSchema.version_schema_name
                    ].TEST.config_id
                  );
                  setSelectedVehicle(vehicle);
                  setShowVehicleJsonEditModal(!showVehicleJsonEditModal);
                }}
              >
                <Text strong>TEST</Text>
              </Button>
              <Button
                size="small"
                style={styleRelease}
                onClick={() => {
                  setSelectedJsonSchemaId(jsonSchema.id);
                  setSelectedVehicleConfigId(
                    vehicle.json_config_versions![
                      jsonSchema.version_schema_name
                    ].RELEASE.config_id
                  );
                  setSelectedVehicle(vehicle);
                  setShowVehicleJsonEditModal(!showVehicleJsonEditModal);
                }}
              >
                <Text strong>RELEASE</Text>
              </Button>
            </Space>
          );
        },
      });
    });
    return columns;
  };

  getJsonConfigColumns();

  // Columns definition for Ant Design Table
  const columns: TableColumnsType<VehicleItem> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      // @ts-ignore
      sorter: (a: { created_at: string | null }, b: { created_at: null }) => {
        if (a.created_at !== null && b.created_at !== null) {
          return a.created_at.localeCompare(b.created_at);
        } else {
          return 0;
        }
      },
    },
    {
      title: "Date Released",
      dataIndex: "date_released",
      key: "date_released",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      ...getColumnSearchProps("manufacturer"),
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      ...getColumnSearchProps("model"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      ...getColumnSearchProps("type"),
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    ...getJsonConfigColumns(),
    {
      title: "Wiring instructions",
      dataIndex: "wiring_instruction",
      key: "wiring_instruction",
      render: (_text: any, record: VehicleItem) => (
        <FilePdfOutlined
          onClick={() => {
            setSelectedVehicle(record);
            setShowPdfEditModal(!showPdfEditModal);
          }}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_text: any, record: VehicleItem) => (
        <div>
          <Button
            style={{ marginRight: "16px" }}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Button danger onClick={() => handleDeleteCar(record)}>
            Delete
          </Button>
        </div>
      ),
    },
    {
      title: "",
      key: "comments",
      render: (_text: any, record: any) => (
        <div>
          <CommentOutlined
            style={{ fontSize: 20 }}
            onClick={() => openCommentModal(record)}
          />
        </div>
      ),
    },
  ];

  const handleDeleteCar = (vehicle: VehicleItem) => {
    setSelectedVehicle(vehicle);
    setShowDeleteModal(true);
  };
  const confirmDeleteCar = (vehicle: VehicleItem) => {
    const carID = vehicle.id;
    setShowDeleteModal(false);
    // Handle delete action, you can console log or implement your logic here
    console.log("Delete Car with ID:", carID);
    axios
      .post(`${BASE_URL}/delete_vehicle/${carID}`)
      .then((response) => {
        vehicleListQuery.refetch();
      })
      .catch((error) => {
        console.error("Error fetching Canny device:", error);
      });
  };

  return (
    <div>
      <CommentsComponent
        showModal={showCommentModal}
        onClose={closeCommentModal}
        commentTarget={"VEHICLE"}
        targetId={selectedVehicleId}
        targetObject={selectedVehicle}
      ></CommentsComponent>

      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Vehicle Management
            <Tooltip title="Add New Vehicle">
              <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={() => {
                  setShowAddVehicleModal(!showAddVehicleModal);
                }}
              />
            </Tooltip>
          </div>
        }
      >
        <Table
          columns={columns}
          dataSource={vehicleListQuery.data}
          loading={vehicleListQuery.isLoading}
        />

        <AddVehicleModal
          showModal={showAddVehicleModal}
          onClose={() => setShowAddVehicleModal(!showAddVehicleModal)}
        />

        <EditWiringPdfModal
          vehicleId={selectedVehicle.id}
          showModal={showPdfEditModal}
          onClose={() => setShowPdfEditModal(!showPdfEditModal)}
        />

        <EditVehicleConfigModal
          vehicle_name={`${selectedVehicle.manufacturer} ${
            selectedVehicle.model
          } ${selectedVehicle.type ?? ""} ${selectedVehicle.year}`}
          schema_id={selectedJsonSchemaId}
          config_id={selectedVehicleConfigId}
          showModal={showVehicleJsonEditModal}
          onClose={() => {
            setSelectedVehicleConfigId(0);
            setSelectedJsonSchemaId(0);
            setShowVehicleJsonEditModal(!showVehicleJsonEditModal);
          }}
        />
        <EditVehicleModal
          showModal={showEditVehicleModal}
          vehicle={selectedVehicle}
          onClose={() => setShowEditVehicleModal(false)}
        />
        <DeleteModal
          showModal={showDeleteModal}
          onDelete={() => confirmDeleteCar(selectedVehicle)}
          onClose={() => setShowDeleteModal(false)}
        />
      </Card>
    </div>
  );
};

export default VehicleListView;
