import React from 'react';

import {Layout} from 'antd';
import {Content} from "antd/lib/layout/layout";

import VehicleListView      from 'Components/VehicleListView';

function MainPage() {

    return (
        <Layout className="layout" style={{height: "100vh"}}>
            <Content style={{margin: '24px 16px 0', overflow: 'initial', height: "100vh", boxSizing: "border-box"}}>
                <VehicleListView/>
            </Content>
        </Layout>
    )
}

export default MainPage;
