import React from 'react';

import {Layout} from 'antd';
import {Space} from 'antd';
import {Content} from "antd/lib/layout/layout";

import FirmwareBaseListView from 'Components/FirmwareBaseListView';
import JsonSchemaListView   from "Components/JsonSchemaListView";


const spaceStyle: React.CSSProperties = {
    marginLeft: 5,
    marginRight: 5,
    width: 500
};
const spaceCarViewStyle: React.CSSProperties = {
    marginLeft: 5,
    marginRight: 5
};


function FrimwareManager() {

    return (
        <Layout className="layout" style={{height: "100vh"}}>
            <Content style={{margin: '24px 16px 0', overflow: 'initial', height: "100vh", boxSizing: "border-box"}}>
                <Space direction="vertical" size={16} style={spaceStyle}>
                    <FirmwareBaseListView/>
                </Space>
                <Space style={{...spaceCarViewStyle, display: "inline-grid"}}>
                    <JsonSchemaListView/>
                </Space>
            </Content>
        </Layout>
    )
}

export default FrimwareManager;
