import React, {useState} from "react";
import axios from 'axios';

import {Input, Modal, Form, Select, Checkbox} from 'antd'; // Import Table from antd

// utils
import {BASE_URL} from 'utils/constants';

import {
    useQuery,
    useQueryClient,
    useMutation,
} from '@tanstack/react-query'
import {VehicleItem} from "utils/interfaces";

interface Props {
    showModal: boolean,
    onClose: () => void
}

interface VehicleType {
    vehicle_manufacturer: string,
    vehicle_model: string
}

type CarMakerListResponse = {
    success: boolean,
    content: string[]
}

const AddVehicleModal: React.FC<Props> = ({showModal, onClose}: Props) => {

    const [form] = Form.useForm<VehicleItem>();

    const [manualManufacturer, setManualManufacturer] = useState(false);
    const [manualModel, setManualModel] = useState(false);

    const carMakerListQuery = useQuery({
        queryKey: ['carMakerList'],
        queryFn: async () => {
            const res = await axios.get<CarMakerListResponse>(`${BASE_URL}/vehicle_types_manufacturer`)
            return res.data.content.map((value: any) => ({
                value: value,
                label: value
            }));
        }
    })

    const carModelListQuery = useQuery({
        queryKey: ['carModelList'],
        queryFn: async () => {
            const res = await axios.get<CarMakerListResponse>(
                `${BASE_URL}/vehicle_models_for_manufacturer/${form.getFieldValue("manufacturer")}`)
            return res.data.content.map((value: any) => ({
                value: value,
                label: value
            }));
        },
        enabled: false
    })

    const queryClient = useQueryClient()

    const addVehicleTypeMutation = useMutation({
        mutationFn: (values: VehicleType) => axios.post(`${BASE_URL}/add_vehicle_type`, values),
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['carMakerList', 'carModelList']}),
    })

    const addVehicleMutation = useMutation({
        mutationFn: (values: VehicleItem) => axios.post(`${BASE_URL}/generate_vehicle`, values),
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['vehicleList']}),
    })

    const onFinish = (values: VehicleItem) => {
        console.log('Received values of form: ', values);
        if (manualManufacturer || manualModel) {
            addVehicleTypeMutation.mutate({
                vehicle_manufacturer: values.manufacturer!,
                vehicle_model: values.model!
            })
        }

        addVehicleMutation.mutate(values, {
            onSuccess: () => onClose(),
            onError: (error) => {
                Modal.error({
                    title: 'Error adding vehicle',
                    content: error.message,
                });
            }
        });
    };

    const getVehicleManufacturerNode = () => {
        return (
            <>
                <>Vehicle Manufacturer</>
                <Checkbox
                    checked={manualManufacturer}
                    onChange={() => {
                        const value = !manualManufacturer
                        setManualManufacturer(value)
                        setManualModel(value)
                    }}>
                    Manual
                </Checkbox>
            </>
        )
    }

    const getVehicleModelNode = () => {
        return (
            <>
                <>Vehicle Model</>
                <Checkbox
                    checked={manualModel}
                    onChange={() => setManualModel(!manualModel)}>
                    Manual
                </Checkbox>
            </>
        )
    }

    const generateYearsArray = (startYear: number) => {
        const currentYear = new Date().getFullYear();
        const yearsArray = [];
        for (let year = startYear; year <= currentYear; year++) {
            yearsArray.push({label: year.toString(), value: year.toString()});
        }
        return yearsArray;
    };

    return (
        <Modal
            title="Add New Vehicle"
            open={showModal}
            okText="Add"
            cancelText="Cancel"
            okButtonProps={{
                autoFocus: false,
                htmlType: 'submit',
            }}
            onCancel={onClose}
            destroyOnClose
            modalRender={(dom) => (
                <Form
                    layout="vertical"
                    form={form}
                    name="form_add_vehicle"
                    clearOnDestroy
                    onFinish={(values) => onFinish(values)}
                >
                    {dom}
                </Form>
            )}>
            <Form.Item label={getVehicleManufacturerNode()}
                       name="manufacturer"
                       rules={[{required: true}]}>
                {!manualManufacturer ? (
                    <Select
                        showSearch
                        filterOption={(input, option) => option?.label.toLowerCase().startsWith(input)}
                        loading={carMakerListQuery.isLoading}
                        options={carMakerListQuery.data}
                        onChange={() => {
                            form.setFieldValue('model', undefined)
                            carModelListQuery.refetch()
                        }}
                    />
                ) : (
                    <Input/>
                )}
            </Form.Item>
            <Form.Item label={getVehicleModelNode()}
                       name="model"
                       rules={[{required: true}]}>
                {!manualModel ? (
                    <Select
                        showSearch
                        filterOption={(input, option) => option?.label.toLowerCase().startsWith(input)}
                        loading={carModelListQuery.isLoading}
                        options={carModelListQuery.data}
                    />
                ) : (
                    <Input/>
                )}
            </Form.Item>
            <Form.Item label="Type"
                       name="type"
                       rules={[{required: false}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Year"
                       name="year"
                       rules={[{required: true}]}>
                <Select
                    options={generateYearsArray(1986)}
                />
            </Form.Item>
        </Modal>)
}
export default AddVehicleModal;

