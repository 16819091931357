import React, {useEffect, useState} from "react";
import axios from 'axios';


import type {CollapseProps} from 'antd';
import type {DescriptionsProps} from 'antd';

import {Button, Collapse, theme, Tooltip} from 'antd';
import {Descriptions, Card} from 'antd';
import {PlusOutlined, EditOutlined} from '@ant-design/icons';
import {
    useMutation,
    useQueryClient,
    useQuery
} from '@tanstack/react-query'

import {useForm} from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

// utils
import {BASE_URL} from 'utils/constants'
import {FirmwareBase, JsonSchemaItem} from "utils/interfaces";

axios.defaults.withCredentials = true


type FirmwareBaseListResponse = {
    success: boolean,
    content: FirmwareBase[]
}

type JsonSchemaListResponse = {
    success: boolean,
    content: JsonSchemaItem[]
}

type FirmwareBaseStatesResponse = {
    success: boolean,
    content: string[]
}


const download = (data: FirmwareBase, type: string, extension: string) => {
    axios({
        url: `${BASE_URL}download_firmware_base/${data.id}/${type}`, //your url
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        const filename = "CANNY_" + type + "_" + data.version_name + "." + extension

        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
}


const cardStyle: React.CSSProperties = {
    height: 'calc(100vh - 150px)',
    width: "100%",
    overflow: "hidden scroll"
};

const FirmwareBaseListView: React.FC = () => {
    const {token} = theme.useToken();

    const queryClient = useQueryClient()

    const [showAddModal, setShowAddModal] = useState(false);

    const {
        getValues,
        watch,
        register,
        setValue,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm<FirmwareBase>();

    const [firmwareBaseList, setFirmwareBase] = useState<any[]>()
    const [showEditFirmwareModal, setEditFirmwareModal] = useState(false)

    const baseFirmwareListQuery = useQuery({
        queryKey: ['baseFirmwareList'],
        queryFn: async () => {
            const res = await axios.get<FirmwareBaseListResponse>(`${BASE_URL}get_firmware_bases`)
            return res.data
        }
    })

    const jsonSchemaListQuery = useQuery({
        queryKey: ['jsonSchemaList'],
        queryFn: async () => {
            const res = await axios.get<JsonSchemaListResponse>(BASE_URL + 'get_json_schemas_base')
            return res.data.content
        }
    })


    const firmwareBaseStatesQuery = useQuery({
        queryKey: ['firmwareBaseStates'],
        queryFn: async () => {
            const res = await axios.get<FirmwareBaseStatesResponse>(`${BASE_URL}get_firmware_bases_states`)
            return res.data.content.map((version: any) => ({
                value: version,
                label: version
            }));
        }
    })

    const addFirmwareBaseMutation = useMutation({
        mutationFn: (data: FirmwareBase) => {
            const formData = new FormData();
            formData.append('binary_app', data.binary_app[0])
            formData.append('binary_app_gbl', data.binary_app_gbl[0])
            formData.append('binary_loader', data.binary_loader[0])
            formData.append("version_name", data.version_name)
            formData.append("commit_hash", data.commit_hash)
            formData.append("changelog", data.changelog)
            formData.append("json_schema_base_id", data.json_schema_base_id.toString())
            return axios.post(`${BASE_URL}/generate_firmware`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        },
        onSuccess: () => {
            toggleAddFirmwareModal();
            reset();
            queryClient.invalidateQueries({ queryKey: ['baseFirmwareList'] })
        }
    })

    const editFirmwareBaseMutation = useMutation({
        mutationFn: (data: FirmwareBase) => {
            console.log("Editing Firmware")
            return axios.post(`${BASE_URL}update_firmware/${data.id}`, {
                "id": data.id,
                "version_name": data.version_name,
                "changelog": data.changelog,
                "commit_hash": data.commit_hash,
                "status": data.status
            })
        },
        onSuccess: () => {
            toggleEditFirmwareModal();
            reset();
            queryClient.invalidateQueries({ queryKey: ['baseFirmwareList'] })
        }
    })

    useEffect(() => {
        // Fetch firmware_bases devices from api
        setFirmwareBase(getApplicationList(baseFirmwareListQuery.data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseFirmwareListQuery.data]);

    const toggleAddFirmwareModal = () => {
        setShowAddModal(!showAddModal)
        reset()
    }

    const toggleEditFirmwareModal = () => {
        setEditFirmwareModal(!showEditFirmwareModal)
        reset()
    }

    const getDescriptions: (data: FirmwareBase) => DescriptionsProps['items'] = (data) => {
        // getJsonSchemas()
        var out_data: DescriptionsProps['items'] = [
            {
                label: <>
                    Commit hash
                </>,
                children: data.commit_hash
            },
            {
                label: "Changelog",
                children: data.changelog
            },
            {
                label: "Status",
                children: data.status

            },
            {
                label: "JSON SCHEMA VERSION",
                children: data.json_schema_base.version_schema_name
            },
            {
                label: 'Download binaries',
                children: <>
                    <Button onClick={() => download(data, "app", "s37")}>Download Firmware App</Button>
                    <Button onClick={() => download(data, "app_gbl", "gbl")}>Download Firmware App GBL</Button>
                    <Button onClick={() => download(data, "loader", "s37")}>Download Firmware Loader</Button>
                </>
            },
        ];
        return out_data;
    }

    const getApplicationList: (data: FirmwareBaseListResponse | undefined)
        => CollapseProps['items'] = (data) => {
        const panelStyle: React.CSSProperties = {
            marginBottom: 24,
            background: token.colorFillAlter,
            borderRadius: token.borderRadiusLG,
            border: 'none',
            width: '100%'
        };

        var out_data: CollapseProps['items'] = []
        if (data?.success) {
            const content = data.content
            for (var firmware_idx in content) {
                console.log(firmware_idx);
                const data = content[firmware_idx];
                out_data.push({
                    key: firmware_idx,
                    label: `${data.version_name} | ${data.created_at} | ${data.status}`,
                    children: <Descriptions
                        layout="vertical"
                        column={{xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1}}
                        bordered
                        items={getDescriptions(data)}/>,
                    style: panelStyle,
                    extra: <EditOutlined style={{"paddingLeft": 100}} onClick={e => {
                        toggleEditFirmwareModal()
                        reset(data)
                    }}/>
                })
            }
        }
        return out_data;
    }

    const renderModal = () => {
        return (
            <Modal show={showAddModal} onHide={toggleAddFirmwareModal}>
                <Modal.Header closeButton>
                    <Modal.Title>New Firmware</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <div>
                                <label htmlFor="firmware_version">Firmware Version Name</label>
                                <input id="firmware_version" defaultValue="" {...register("version_name", {
                                    required: true,
                                })} />
                                {errors.version_name && <span>This field is required</span>}
                            </div>
                            <div>
                                <label htmlFor="commit_hash">Commit hash</label>
                                <input id="commit_hash" defaultValue="" {...register("commit_hash", {
                                    required: true,
                                })} />
                                {errors.commit_hash && <span>This field is required</span>}
                            </div>
                            <div>
                                <label htmlFor="firmware_changelog">Changelog</label>
                                <input id="firmware_changelog" defaultValue="" {...register("changelog", {
                                    required: true,
                                })} />
                                {errors.changelog && <span>This field is required</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="select_json_schema">Select the Schema Version</label>
                                <Select
                                    id="select_json_schema"
                                    isClearable
                                    isSearchable
                                    // defaultValue={jsonSchemaListQuery.data[0]}
                                    value={jsonSchemaListQuery.data?.find(state => state.id === watch('json_schema_base_id'))}
                                    options={jsonSchemaListQuery.data}
                                    getOptionLabel={(option) => option.version_schema_name}
                                    getOptionValue={(option) => `${option.id}`}
                                    onChange={(selectedOption) => selectedOption && setValue("json_schema_base_id", selectedOption.id as number)}
                                />
                            </div>
                            <div>
                                <label htmlFor="firmware_file_loader">Firmware Loader</label>
                                <input type="file" id="firmware_file_loader"
                                       accept=".s37" {...register("binary_loader", {
                                    required: true,
                                })} />
                                {errors.binary_loader && <span>This field is required</span>}
                            </div>
                            <div>
                                <label htmlFor="firmware_file_app">Firmware App</label>
                                <input type="file" id="firmware_file_app"
                                       accept=".s37" {...register("binary_app", {
                                    required: true,
                                })} />
                                {errors.binary_app && <span>This field is required</span>}
                            </div>
                            <div>
                                <label htmlFor="firmware_file_app_gbl">Firmware App</label>
                                <input type="file" id="firmware_file_app_gbl"
                                       accept=".gbl" {...register("binary_app_gbl", {
                                    required: true,
                                })} />
                                {errors.binary_app && <span>This field is required</span>}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="default" onClick={toggleAddFirmwareModal}>
                        Close
                    </Button>
                    <Button type="primary" onClick={handleSubmit((data) => addFirmwareBaseMutation.mutate(data))}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderEditFirmwareModal = () => {
        return (
            <Modal show={showEditFirmwareModal} onHide={toggleEditFirmwareModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Firmware</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <div>
                                <label htmlFor="firmware_version">Firmware Version Name</label>
                                <input id="firmware_version" defaultValue="" {...register("version_name", {
                                    required: true,
                                })} />
                                {errors.version_name && <span>This field is required</span>}
                            </div>
                            <div>
                                <label htmlFor="commit_hash">Commit hash</label>
                                <input id="commit_hash" defaultValue="" {...register("commit_hash", {
                                    required: true,
                                })} />
                                {errors.commit_hash && <span>This field is required</span>}
                            </div>
                            <div>
                                <label htmlFor="firmware_changelog">Changelog</label>
                                <input id="firmware_changelog" defaultValue="" {...register("changelog", {
                                    required: true,
                                })} />
                                {errors.changelog && <span>This field is required</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="select_firmware_state">Select the firmware state</label>
                                <Select
                                    id="select_firmware_state"
                                    isClearable
                                    isSearchable
                                    value={firmwareBaseStatesQuery.data?.find(state => state.value === watch('status'))}
                                    options={firmwareBaseStatesQuery.data}
                                    onChange={(selectedOption) => selectedOption && setValue("status", selectedOption.value as string)}
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="default" onClick={toggleEditFirmwareModal}>
                        Close
                    </Button>
                    {/*<Button type="primary" onClick={handleSubmit(editFirmware)}>*/}
                    <Button type="primary" onClick={_ => {
                        editFirmwareBaseMutation.mutate(getValues());
                    }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <div>
            <Card
                title={
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        Firmware Base List
                        <Tooltip title="Add New Firmware">
                            <Button type="primary" shape="circle" icon={<PlusOutlined/>} onClick={toggleAddFirmwareModal}/>
                        </Tooltip>
                    </div>
                }
                style={cardStyle}
                loading={baseFirmwareListQuery.isPending}
            >
                {
                    baseFirmwareListQuery.error
                        ? <>{baseFirmwareListQuery.error.message}</>
                        : <Collapse
                            collapsible="icon"
                            bordered={false}
                            items={firmwareBaseList}
                            style={{background: token.colorBgContainer}}/>
                }
            </Card>
            {renderModal()}
            {renderEditFirmwareModal()}
        </div>
    )
};

export default FirmwareBaseListView;
