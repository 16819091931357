import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ApplciationDataState {
  name: string;
  key: string;
  id: number;
}

export interface CarConfigurationState {
  name: string;
  key: string;
  id: number;
}

export interface CarConfigurationListState {
  [key: string]: CarConfigurationState
}

interface GeneratorDataState {
  selectedApplication: ApplciationDataState;
  selectedConfigurations: CarConfigurationListState;
};

const initialState: GeneratorDataState = {
  selectedApplication: {name: "", key: "", id:0},
  selectedConfigurations: {},
};

export const generatorDataSlice = createSlice({
  name: 'generatorData',
  initialState,
  reducers: {
    setSelectedApplication: (state, action: PayloadAction<ApplciationDataState>) => {
      state.selectedApplication = action.payload;
    },
    addSelectedConfiguration: (state, action: PayloadAction<CarConfigurationState>) => {
      if(!(action.payload.key in state.selectedConfigurations)){
        state.selectedConfigurations[action.payload.key] = action.payload;
      }
    },
    removeSelectedConfiguration: (state, action: PayloadAction<CarConfigurationState>) => {
      if(action.payload.key in state.selectedConfigurations){
        delete state.selectedConfigurations[action.payload.key];
      }
    },

  },
})

// Action creators are generated for each case reducer function
export const {
  setSelectedApplication,
  addSelectedConfiguration,
  removeSelectedConfiguration
} = generatorDataSlice.actions

export default generatorDataSlice.reducer