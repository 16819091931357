import { configureStore } from '@reduxjs/toolkit'
import generatorDataReducer from './generatorDataSlice'

export const store =  configureStore({
  reducer: {
    generatorData: generatorDataReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;