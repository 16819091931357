import React, {useEffect} from "react";
import {Button, Divider, Modal} from "antd";
import {useState} from "react";
import {Comment} from "../utils/interfaces";
import {BASE_URL} from "../utils/constants";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";

interface Props{
    showModal: boolean,
    onClose: () => void,
    commentTarget: string,
    targetId: number,
    targetObject: any
}

const CommentsComponent : React.FC<Props> = ({showModal,onClose, commentTarget, targetId, targetObject}) => {
    const [commentsList, setCommentsList] = useState<Comment[]>([])
    const [newComment, setNewComment] = useState<string>("")

    useEffect(()=>{
        if(showModal){
            axios.post(BASE_URL + 'get_comments',{
                target: commentTarget,
                target_id: targetId
            })
                .then(function (response) {
                    console.log(response)
                    setCommentsList(response.data.content)
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },[targetId,commentTarget, showModal]);
    const handleInputChange = (e:any) => {
        setNewComment(e.target.value);
    }
    const handleAddComment = () => {
        const newCommentObject = {
            target: commentTarget,
            target_id: targetId,
            comment: newComment
        }

        axios.post(BASE_URL + 'add_comment',newCommentObject)
            .then(function (response) {
                console.log(response)
                setNewComment('');
                onClose();
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    const comments = commentsList.map((comment) =>
        <>
        <li>{comment.formatted_comment}</li>
        <Divider/>
        </>

    ).reverse();
    return(
        <Modal footer={null} destroyOnClose open={showModal}  onCancel={onClose} title={"Comments"}>
            <div style={{marginBottom: 16}} >
                <TextArea onChange={(e) => handleInputChange(e)} />
                <Button onClick={handleAddComment} >Add Comment</Button>
            </div>
            <ul>
                {comments}
            </ul>
        </Modal>
    )
}
export default CommentsComponent;