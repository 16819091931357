import React, { useEffect, useState } from "react";
import axios from "axios";

import { App, Checkbox, Form, Input, Modal, Select } from "antd"; // Import Table from antd
// utils
import { BASE_URL } from "utils/constants";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { VehicleItem } from "utils/interfaces";

interface Props {
  showModal: boolean;
  vehicle: VehicleItem;
  onClose: () => void;
}

type CarMakerListResponse = {
  success: boolean;
  content: string[];
};

const EditVehicleModal: React.FC<Props> = ({
  showModal,
  vehicle,
  onClose,
}: Props) => {
  const { modal } = App.useApp();

  const [form] = Form.useForm<VehicleItem>();

  const [manualManufacturer, setManualManufacturer] = useState(false);
  const [manualModel, setManualModel] = useState(false);
  const [modelList, setModelList] = useState<any[]>([]);
  const [makerList, setMakerList] = useState<any[]>([]);

  const initialFormValues = {
    manufacturer: vehicle.manufacturer,
    model: vehicle.model,
    type: vehicle.type,
    year: vehicle.year,
  };

  const carMakerListQuery = useQuery({
    queryKey: ["carMakerList"],
    queryFn: async () => {
      const res = await axios.get<CarMakerListResponse>(
        `${BASE_URL}/vehicle_types_manufacturer`
      );
      let list = res.data.content.map((value: any) => ({
        value: value,
        label: value,
      }));
      setMakerList(list);
      form.setFieldValue("manufacturer", initialFormValues.manufacturer);
      return list;
    },
  });

  const carModelListQuery = useQuery({
    queryKey: ["carModelList"],
    queryFn: async () => {
      const res = await axios.get<CarMakerListResponse>(
        `${BASE_URL}/vehicle_models_for_manufacturer/${form.getFieldValue(
          "manufacturer"
        )}`
      );
      let list = res.data.content.map((value: any) => ({
        value: value,
        label: value,
      }));
      setModelList(list);
      form.setFieldValue("model", initialFormValues.model);
      return list;
    },
    enabled: false,
  });

  // absolute spaghetti
  useEffect(() => {
    if (showModal) {
      carMakerListQuery.refetch().then(() => {
        carModelListQuery.refetch();
      });
    }
    // this will make an infinite loop if carMakerListQuery is included in dependencies
    // eslint-disable-next-line
  }, [showModal]);
  const queryClient = useQueryClient();

  const onFinish = (values: VehicleItem) => {
    values.id = vehicle.id;
    console.log("Received values of form: ", values);

    // var formData = new FormData;
    // formData.append('json_config', JSON.parse(values.json_config))
    updateVehicleMutation.mutate(values, {
      onSuccess: () => onClose(),
      onError: (error) => {
        modal.error({
          title: "Error editing vehicle",
          content: error.message,
        });
      },
    });
  };

  const updateVehicleMutation = useMutation({
    mutationFn: (values: VehicleItem) => {
      return axios.post(`${BASE_URL}/update_vehicle`, values, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["vehicleList"] }),
  });

  const getVehicleManufacturerNode = () => {
    return (
      <>
        <>Vehicle Manufacturer </>
        <Checkbox
          checked={manualManufacturer}
          onChange={() => {
            const value = !manualManufacturer;
            setManualManufacturer(value);
            setManualModel(value);
          }}
        >
          Manual
        </Checkbox>
      </>
    );
  };

  const getVehicleModelNode = () => {
    return (
      <>
        <>Vehicle Model</>
        <Checkbox
          checked={manualModel}
          onChange={() => setManualModel(!manualModel)}
        >
          Manual
        </Checkbox>
      </>
    );
  };

  const generateYearsArray = (startYear: number) => {
    const currentYear = new Date().getFullYear();
    const yearsArray = [];
    for (let year = startYear; year <= currentYear; year++) {
      yearsArray.push({ label: year.toString(), value: year.toString() });
    }
    return yearsArray;
  };

  return (
    <Modal
      title={"Editing vehicle: " + vehicle.manufacturer + " " + vehicle.model}
      open={showModal}
      okText="Submit"
      cancelText="Cancel"
      okButtonProps={{
        autoFocus: false,
        htmlType: "submit",
      }}
      onCancel={onClose}
      destroyOnClose={true}
      modalRender={(dom) => (
        <Form
          layout="vertical"
          form={form}
          name="form_edit_vehicle"
          clearOnDestroy
          onFinish={(values) => onFinish(values)}
        >
          {dom}
        </Form>
      )}
    >
      <Form.Item
        label={getVehicleManufacturerNode()}
        name="manufacturer"
        rules={[{ required: true }]}
      >
        {!manualManufacturer ? (
          <Select
            showSearch
            filterOption={(input, option) =>
              option?.label.toLowerCase().startsWith(input)
            }
            loading={carMakerListQuery.isLoading}
            options={makerList}
            onSelect={() => {
              carModelListQuery.refetch().then(() => {
                form.setFieldValue("model", null);
              });
            }}
          />
        ) : (
          <Input />
        )}
      </Form.Item>
      <Form.Item
        label={getVehicleModelNode()}
        name="model"
        rules={[{ required: true }]}
      >
        {!manualModel ? (
          <Select
            showSearch
            filterOption={(input, option) =>
              option?.label.toLowerCase().startsWith(input)
            }
            loading={carModelListQuery.isLoading}
            options={modelList}
          />
        ) : (
          <Input />
        )}
      </Form.Item>
      <Form.Item
        initialValue={vehicle.type}
        label="Type"
        name="type"
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        initialValue={vehicle.year}
        label="Year"
        name="year"
        rules={[{ required: true }]}
      >
        <Select options={generateYearsArray(1986)} />
      </Form.Item>
    </Modal>
  );
};
export default EditVehicleModal;
