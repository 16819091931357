
import React from "react";
import axios from 'axios';

import {Input, Modal, Form} from 'antd'; // Import Table from antd

// utils
import {BASE_URL} from 'utils/constants';
import {JsonSchemaItem} from "utils/interfaces";

import {
    useQueryClient,
    useMutation,
} from '@tanstack/react-query'

interface Props {
    jsonSchema: JsonSchemaItem,
    showModal: boolean,
    onClose: () => void
}



const JsonSchemaListViewEditModal: React.FC<Props> = ({ jsonSchema, showModal, onClose: onCancel }: Props) => {

    const [form] = Form.useForm<JsonSchemaItem>();

    const queryClient = useQueryClient()

    const editJsonSchema = useMutation({
        // mutationFn: (values: JsonSchemaItem) => axios.post(`${BASE_URL}update_json_schema_base/${jsonSchema.id}`, values),
        mutationFn: (values: JsonSchemaItem) => {
            return axios.post(`${BASE_URL}/update_json_schema_base/${jsonSchema.id}`, values, {
                headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
              }})
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['jsonSchemaList'] })
          queryClient.invalidateQueries({ queryKey: ['baseFirmwareList'] })
          queryClient.invalidateQueries({ queryKey: ['vehicleList'] })
        }
      })

    const onFinish = (values: JsonSchemaItem) => {
      console.log('Received values of form: ', values);
      values.json_schema = JSON.parse(values.json_schema as string)
      editJsonSchema.mutate(values, {
        onSuccess: () =>  onCancel(),
        onError: (error) => {
            Modal.error({
                title: 'Error editing vehicle',
                content: error.message,
            });
        }
      });
    };

    return(
    <Modal
        title="Add New JSON Schema"
        open={showModal}
        okText="Add"
        cancelText="Cancel"
        okButtonProps={{
          autoFocus: false,
          htmlType: 'submit',
        }}
        onCancel={onCancel}
        destroyOnClose
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form_edit_json_schema"
            clearOnDestroy
            onFinish={(values) => onFinish(values)}
          >
            {dom}
          </Form>
        )}>
        <Form.Item
          name="json_schema"
          label="JSON Schema"
          initialValue={JSON.stringify(jsonSchema?.json_schema, undefined, 4)}
          rules={[
            {
              required: true,
              validator: (_, value: string) =>{
                    if(value) {
                        try {
                            JSON.parse(value);
                            return Promise.resolve()
                        } catch (e) {
                            return Promise.reject(new Error('Invalid JSON'))
                        }
                    } else {
                        return Promise.reject(new Error('Please enter JSON Schema'))
                    }
                }
            },
          ]}>
          <Input.TextArea rows={30} />
        </Form.Item>
    </Modal>)
}
export default JsonSchemaListViewEditModal;

