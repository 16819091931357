const check_url = (url_origin: string | URL) => {
    const url = new URL(url_origin);
    if (parseInt(url.port) === 3000) {
        // Development server
        url.hostname = "localhost";
        url.port = "5000";
    }
    return url.toString();
};

export const BASE_URL = check_url(window.location.origin);
