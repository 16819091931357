import React, {useState} from "react";
import { Button, Modal, Typography } from 'antd';
import axios from 'axios';

import { BASE_URL } from '../utils/constants'
axios.defaults.withCredentials = true
const { Title } = Typography;

function LoginPage() {
    const [loading, setLoading] = useState(false);

    // A handler/callback function
    const handleLogin = () => {
        setLoading(true)
        axios.get(BASE_URL + 'get-login-url')
        .then(function (response) {
            window.location.href = response.data
        })
        .catch(function(error) {
            console.log(error);
            setLoading(false)
        })
    };

    // Add a customized button to the footer
    var footer = [
        <Button key="submit" type="primary" loading={loading} onClick={handleLogin}>
        Login with SSO
        </Button>,
    ]

    return (
        <Modal
            closeIcon = {null}
            open = {true}
            footer = {footer}
            width = {800}>
            <Title style={{textAlign: "center"}}>Welcome to CANNY web application</Title>
        </Modal>
    )
}

export default LoginPage;
